import Game from './components/Game';
import Creator from './components/Creator';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';

function App() {
  useEffect(() => {
    ReactGA.initialize('G-82T7CNW8KB');
  }, [])

  const GameComponent = () => {
    return (
      <div style={{height: "100vh"}}>
        <Game />
      </div>
    )
  }

  return (
    <CookiesProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <GameComponent /> } />
          <Route path="skapa" element={ <Creator /> } />
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
  );
}

export default App;
